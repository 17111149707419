import { Link } from 'gatsby';
import { NamespaceProvider, Translate } from 'ns-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ENFlag from '../images/gb.svg';
import HUFlag from '../images/hu.svg';
import Logo from '../images/logo.svg';
import './header.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toggleMenu: false };
  }

  handleBurgerClick = () => {
    const { toggleMenu } = this.state;
    this.setState({ toggleMenu: !toggleMenu });
    if (!toggleMenu) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }
  };

  render() {
    const { lng, location } = this.props;

    const isPrivacyPolicyPage = location.pathname.includes('privacy');
    return (
      <div
        style={{
          background: `white`,
          marginBottom: `1.45rem`,
          border: '1px solid rgba(0,40,80,0.10)',
          boxShadow: '0 5px 20px 0 rgba(0,40,80,0.10)'
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1440,
            padding: `1.45rem 1.0875rem`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {!this.state.toggleMenu && (
            <React.Fragment>
              <NamespaceProvider ns="translations">
                <Link to={'/'}>
                  <Logo />
                </Link>
                <ul className="header-container__links">
                  <Navigator
                    href="#section-2"
                    isScrollableLink={!isPrivacyPolicyPage}
                  >
                    <li style={{ minWidth: '120px' }}>
                      <Translate>header_link1</Translate>{' '}
                      <div className="header-container__links--separator"></div>
                    </li>
                  </Navigator>
                  <Navigator
                    href="#section-3"
                    isScrollableLink={!isPrivacyPolicyPage}
                  >
                    <li style={{ minWidth: '140px' }}>
                      <Translate>header_link2</Translate>
                      <div className="header-container__links--separator"></div>
                    </li>
                  </Navigator>
                  {/* <Navigator
                    isScrollableLink={!isPrivacyPolicyPage}
                    href="#section-4"
                  >
                    <li style={{ minWidth: '80px' }}>
                      <Translate>header_link3</Translate>
                      <div className="header-container__links--separator"></div>
                    </li>
                  </Navigator> */}
                  <Navigator
                    isScrollableLink={!isPrivacyPolicyPage}
                    href="#section-5"
                  >
                    <li style={{ minWidth: '80px' }}>
                      <Translate>header_link4</Translate>
                      <div className="header-container__links--separator"></div>
                    </li>
                  </Navigator>
                  {lng === 'en' && (
                    <li style={{ width: '30px' }}>
                      <Link to="/">
                        <HUFlag />
                      </Link>
                      <div className="header-container__links--separator"></div>
                    </li>
                  )}
                  {lng === 'hu' && (
                    <li style={{ width: '30px' }}>
                      <Link to="/en">
                        <ENFlag />
                      </Link>
                      <div className="header-container__links--separator"></div>
                    </li>
                  )}
                </ul>
                <div className="header-container__flag">
                  {lng === 'en' && (
                    <div style={{ width: '30px' }}>
                      <Link to="/">
                        <HUFlag />
                      </Link>
                    </div>
                  )}
                  {lng === 'hu' && (
                    <div style={{ width: '30px' }}>
                      <Link to="/en">
                        <ENFlag />
                      </Link>
                    </div>
                  )}
                </div>
                <div
                  className={`hamburger-container ${
                    this.state.toggleMenu ? 'change' : ''
                  }`}
                  onClick={this.handleBurgerClick}
                >
                  <div className="hamburger-bar1"></div>
                  <div className="hamburger-bar2"></div>
                  <div className="hamburger-bar3"></div>
                </div>
              </NamespaceProvider>
            </React.Fragment>
          )}

          {this.state.toggleMenu && (
            <div className="menu-overlay">
              <div className="menu-overlay-top">
                <Link to={'/'}>
                  <Logo />
                </Link>
                <div
                  className={`hamburger-container ${
                    this.state.toggleMenu ? 'change' : ''
                  }`}
                  onClick={this.handleBurgerClick}
                >
                  <div className="hamburger-bar1"></div>
                  <div className="hamburger-bar2"></div>
                  <div className="hamburger-bar3"></div>
                </div>
              </div>
              <ul className="header-container__mobile-links">
                <AnchorLink
                  href="#section-2"
                  offset={() =>
                    document.getElementsByClassName(
                      'header-container__mobile-links'
                    )[0].offsetHeight +
                    document.getElementsByClassName('menu-overlay-top')[0]
                      .offsetHeight
                  }
                  onClick={this.handleBurgerClick}
                >
                  <li>
                    <Translate>header_link1</Translate>{' '}
                    <div className="header-container__links--separator"></div>
                  </li>
                </AnchorLink>
                <AnchorLink
                  href="#section-3"
                  offset={() =>
                    document.getElementsByClassName(
                      'header-container__mobile-links'
                    )[0].offsetHeight +
                    document.getElementsByClassName('menu-overlay-top')[0]
                      .offsetHeight
                  }
                  onClick={this.handleBurgerClick}
                >
                  <li>
                    <Translate>header_link2</Translate>
                    <div className="header-container__links--separator"></div>
                  </li>
                </AnchorLink>
                {/* <AnchorLink
                  href="#section-4"
                  offset={() =>
                    document.getElementsByClassName(
                      'header-container__mobile-links'
                    )[0].offsetHeight +
                    document.getElementsByClassName('menu-overlay-top')[0]
                      .offsetHeight
                  }
                  onClick={this.handleBurgerClick}
                >
                  <li>
                    <Translate>header_link3</Translate>
                    <div className="header-container__links--separator"></div>
                  </li>
                </AnchorLink> */}
                <AnchorLink
                  href="#section-5"
                  offset={() =>
                    document.getElementsByClassName(
                      'header-container__mobile-links'
                    )[0].offsetHeight +
                    document.getElementsByClassName('menu-overlay-top')[0]
                      .offsetHeight
                  }
                  onClick={this.handleBurgerClick}
                >
                  <li>
                    <Translate>header_link4</Translate>
                    <div className="header-container__links--separator"></div>
                  </li>
                </AnchorLink>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Header;

const Navigator = ({ isScrollableLink, href, children }) => {
  return isScrollableLink ? (
    <AnchorLink href={href}>{children}</AnchorLink>
  ) : (
    <Link to={`/${href}`}>{children}</Link>
  );
};
