import { graphql, StaticQuery } from 'gatsby';
import * as i18n from 'i18next';
import { I18NextProvider, Translate } from 'ns-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import FacebookImage from '../images/facebook.png';
import InstagramImage from '../images/instagram.png';
import Logo from '../images/logo-white.png';
import Header from './header';

class Layout extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <I18NextProvider i18n={i18n}>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <Header
                {...this.props}
                siteTitle={data.site.siteMetadata.title}
              />
              <div
                style={{
                  margin: `0 auto`,
                  paddingTop: 0
                }}
              >
                {this.props.children}
                <footer className="footer">
                  <div className="footer-container">
                    <div className="footer-item footer-container__logo">
                      <img src={Logo}></img>
                      <div className="footer-container__logo--text">
                        <p>2019 - {currentYear} © LA-IR BT.</p>
                        <p>
                          <Translate>footer-all_rights_reserved</Translate>
                        </p>
                      </div>
                    </div>
                    <div className="footer-item footer-container__info">
                      <p>
                        <Translate>footer-first_app</Translate>
                      </p>
                      <p className="text-yellow footer-container__info--details">
                        hello.doggoapp@gmail.com <br />
                        +36706721986
                      </p>
                    </div>
                    <div className="footer-item footer-container__link">
                      <ul>
                        <AnchorLink href="#section-2">
                          <li>
                            <Translate>header_link1</Translate>
                          </li>
                        </AnchorLink>
                        <AnchorLink href="#section-3">
                          <li>
                            <Translate>header_link2</Translate>
                          </li>
                        </AnchorLink>
                        {/* <AnchorLink href="#section-4">
                          <li>
                            <Translate>header_link3</Translate>
                          </li>
                        </AnchorLink> */}
                        <AnchorLink href="#section-5">
                          <li>
                            <Translate>header_link4</Translate>
                          </li>
                        </AnchorLink>
                        <a href="/aszf.pdf" target="_blank">
                          <li>
                            <Translate>termsOfUse</Translate>
                          </li>
                        </a>
                        <a href="/privacy-policy" target="_blank">
                          <li>
                            <Translate>privacyPolicy</Translate>
                          </li>
                        </a>
                      </ul>
                    </div>
                    <div className="footer-item footer-container__social">
                      <div className="footer-container__social--item">
                        <img src={FacebookImage}></img>
                        <span className="hide-mobile">
                          <Translate>follow_us</Translate>
                          <br />
                          <span className="semiBold">
                            <a
                              href="https://www.facebook.com/doggocommunity/"
                              target="_blank"
                            >
                              <Translate>facebook</Translate>
                            </a>
                          </span>
                        </span>
                      </div>
                      <div className="footer-container__social--item">
                        <img src={InstagramImage}></img>
                        <span className="hide-mobile">
                          <Translate>follow_us</Translate>
                          <br />
                          <span className="semiBold">
                            <a
                              href="https://www.instagram.com/doggohungary/"
                              target="_blank"
                            >
                              <Translate>instagram</Translate>
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </>
          )}
        />
      </I18NextProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
